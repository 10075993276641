import React from 'react'
import ReactCursorPosition from "@appinfini/react-cursor-position"
import TestingComponent from '../components/Testing-comp'

import Layout from '../components/layout'
import SEO from '../components/seo'

export default function Testing(props) {
  
  return (
    <Layout>
      <SEO title={`Testing page`}/>
      <ReactCursorPosition>
        <TestingComponent />
      </ReactCursorPosition>
    </Layout>
  );
}



