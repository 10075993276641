import React, { Component } from 'react'

export default class TestingComp extends Component {
  render() {
    console.log(this.props)
    return (
      <div style={{position:`absolute`, width:`100%`, color:`white`, zIndex:-1}}>
        {`x: ${this.props.position.x}`}<br />
        {`y: ${this.props.position.y}`}<br />
        <p>{this.props.title}</p>
    </div>
    )
  }
}
